



































































































































import { gsap } from 'gsap'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { SwitcherItem } from '@/inc/types/components'

export default defineComponent({
  name: 'switcher',
  components: {},
  props: {
    items: {
      type: Array as () => SwitcherItem[],
      required: true,
    },
  },

  setup(props, ctx) {
    const activeOption = ref<SwitcherItem | undefined>(undefined)
    const activeOptionEl = ref<HTMLElement | undefined>(undefined)
    const options = ref<HTMLElement[]>([])
    const slider = ref<HTMLElement | null>(null)

    const setSliderPosition = () =>
      new Promise(resolve => {
        setTimeout(() => {
          activeOptionEl.value = options.value.find(el =>
            el.classList.contains('is-active')
          )

          if (!activeOptionEl.value) {
            return
          }

          gsap.to(slider.value, 0.5, {
            width: activeOptionEl.value.offsetWidth,
            x: activeOptionEl.value.offsetLeft - slider.value!.offsetLeft,
            ease: 'Power4.easeOut',
            onComplete: resolve,
          })
        }, 50)
      })

    const setAcitve = async (item: SwitcherItem) => {
      activeOption.value = item
      ctx.emit('input', item.value) // For some reason 'change' does not work
      await setSliderPosition()
    }

    const onResize = () => {
      setSliderPosition()
    }

    onMounted(() => {
      activeOption.value = props.items.find(item => item.isActive)

      if (activeOption.value) {
        setAcitve(activeOption.value)
      }
    })

    return { activeOption, onResize, options, setAcitve, slider }
  },
})
