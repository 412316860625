


























import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

import VInput from '@/components/form/VInput.vue'

export default defineComponent({
  name: 'auto-suggest',
  components: { VInput },
  props: {
    model: {
      type: Object as () => Record<string, any>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array as () => Record<string, any>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { isAdmin } = useGetters('user', ['isAdmin'])
    const { i18n } = useState('commune', ['i18n'])
    const search = ref('')
    const isOpen = ref(false)
    const results = ref()
    const filterResults = () => {
      results.value = props.items.filter(item =>
        item.toLowerCase().includes(props.model.Departement.toLowerCase())
      )
    }

    const onChange = () => {
      filterResults()
      if (results.value.length > 0) {
        isOpen.value = true
      } else {
        isOpen.value = false
      }
    }

    const setResult = data => {
      props.model.Departement = data
      isOpen.value = false
    }
    const close = () => {
      isOpen.value = false
    }

    return {
      isAdmin,
      i18n,
      search,
      onChange,
      isOpen,
      results,
      setResult,
      close,
    }
  },
})
