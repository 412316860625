import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import userAccountRequest from '@/composables/userAccountRequest'
import { AdresseEan, CommuneRole, EanData, ExportConsoEp } from '@/inc/types'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import dayjs from 'dayjs'
import { convertRegister, convertUnit } from '@/inc/utils'

export interface Commune {
  uid: string
  fullname: string
  departement: string
  roles: string[]
  rolesShow: string
  actif: string
}

export interface CommuneRacc {
  adresse: string
  rue: string
  demande: string
  reference: string
  status: string
  ean: string
  cloture: boolean
  action: boolean
}

export interface CommuneConsommationEP {
  codePostal: string
  nbrEpLed: string
  nbrEpNonLed: string
  consomation: string
  ean: string
  type: string
}

export interface CommuneContact {
  name?: string
  mail?: string
  phone?: string
  picture?: string
}

export interface CommuneContactList {
  departement: string
  libelle?: string
  contacts: Array<CommuneContact>
}

export interface CommuneEpHistorique {
  consommation: string
  date: string
}

export interface CommuneDataConsoEp {
  name: string
  data: Record<any, any>
  color: string
  total: number
}

export interface CommuneEp {
  nbrEpLed: string
  nbrEpNonLed: string
  consommationTotal: string
  historique: CommuneEpHistorique[]
}

// fonction formattant ma liste des user pour les afficher dans le tableau
export const getUsersList = () => {
  const dataList = [] as Commune[]
  const { i18n } = useState('commune', ['i18n'])
  const { getUsers } = useGetters('commune', ['getUsers'])
  let titleactif = ''
  for (const item of getUsers.value) {
    if (item.valide && item.actif) {
      titleactif = i18n.value.userGestion.actif
    } else if (item.valide && !item.actif) {
      titleactif = i18n.value.userGestion.inactif
    } else {
      titleactif = i18n.value.userGestion.nonvalide
    }
    dataList.push({
      uid: item.uid,
      fullname: item.fullname,
      departement: item.departement,
      roles: item.roles,
      rolesShow: transformListRole(item.roles),
      actif: titleactif,
    })
  }

  return dataList
}
// fonction formattant ma liste des roles pour les afficher dans le tableau
const transformListRole = roles => {
  let result = ''
  for (let i = 0; i < roles.length; i++) {
    if (i === 0) {
      result += `${labelRoles(roles[i])}`
    } else {
      result += `<br/>${labelRoles(roles[i])}`
    }
  }

  return result
}
// fonction permettant de remplir les champs pour editer un user
export const editPrefill = async uid => {
  const { res, error } = await userAccountRequest(`town/users/${uid}`, 'get')
  // console.log(res?.data)
  if (!error) {
    return {
      Uid: res?.data.uid,
      Nom: res?.data.nom,
      Prenom: res?.data.prenom,
      Departement: res?.data.departement,
      Fonction: res?.data.fonction,
      Email: res?.data.email,
      Tel: res?.data.tel,
      Actif: res?.data.actif,
      Roles: res?.data.roles,
      Valide: res?.data.valide,
    }
  }

  return {}
}
// fonction retournant la liste des departements trouver dans les users afin de les trier par departement
export const tableDepartement = () => {
  const { getUsers } = useGetters('commune', ['getUsers'])
  const table: string[] = []
  for (const item of getUsers.value) {
    if (!table.includes(item.departement)) {
      table.push(item.departement)
    }
  }

  return table
}
// fonction retournant la liste des roles par id en tableau de string d'id
export const tableRoles = () => {
  const { getTableRoles } = useGetters('commune', ['getTableRoles'])
  const { FETCH_ROLES } = useActions('commune', ['FETCH_ROLES'])
  if (getTableRoles.value.length < 1) {
    FETCH_ROLES()
  }

  return getTableRoles.value
}
// fonction retournant un text en fonction de l'id rentrer
export const labelRoles = value => {
  const { getLabelRoles } = useGetters('commune', ['getLabelRoles'])
  const table: CommuneRole[] = getLabelRoles.value

  return table.find(x => x.id === value)?.text
}

// fonction formattant ma liste des user pour les afficher dans le tableau
export const getRaccList = () => {
  const dataList = [] as CommuneRacc[]
  const { i18n } = useState('commune', ['i18n'])
  const { getRecords } = useGetters('commune', ['getRecords'])

  for (const record of getRecords.value) {
    dataList.push({
      reference: record.reference,
      adresse: `${record.adresse.rue}, ${record.adresse.numRue}`,
      rue: record.adresse.rue,
      demande:
        record.type === 'MODIF' ? i18n.value.racc.modif : i18n.value.racc.new,
      status: record.statut,
      ean: record.ean,
      cloture: record.cloture,
      action: record.action,
    })
  }

  return dataList
}

export const tableEan = (switcher = null) => {
  const table: string[] = []
  const data = getRaccList()
  if (switcher === null) {
    for (const item of data) {
      if (!table.includes(item.ean) && item.ean) {
        table.push(item.ean)
      }
    }
  } else {
    for (const item of data) {
      if (!table.includes(item.ean) && item.cloture === switcher && item.ean) {
        table.push(item.ean)
      }
    }
  }

  return table
}

export const tableTypeRacc = () => {
  const { i18n } = useState('commune', ['i18n'])

  const table = [i18n.value.racc.modif, i18n.value.racc.new]

  return table
}

export const formatTypeCompteur = cptType => {
  const { i18n } = useState('commune', ['i18n'])

  const table = [
    { type: 'elec', value: i18n.value.relevesIndex.energie.elec },
    { type: 'gaz', value: i18n.value.relevesIndex.energie.gaz },
  ]

  return table.find(x => x.type.toLowerCase() === cptType.toLowerCase())?.value
}

export const tableAdresse = (switcher = null) => {
  const data = getRaccList()
  const table: string[] = []
  if (switcher === null) {
    for (const item of data) {
      if (!table.includes(item.rue)) {
        table.push(item.rue)
      }
    }
  } else {
    for (const item of data) {
      if (!table.includes(item.rue) && item.cloture === switcher) {
        table.push(item.rue)
      }
    }
  }

  return table
}
export const tableStatus = (switcher = null) => {
  const data = getRaccList()
  const table: string[] = []
  if (switcher === null) {
    for (const item of data) {
      if (!table.includes(item.status)) {
        table.push(item.status)
      }
    }
  } else {
    for (const item of data) {
      if (!table.includes(item.status) && item.cloture === switcher) {
        table.push(item.status)
      }
    }
  }

  return table
}

export const getConsoEp = () => {
  const data = [] as CommuneConsommationEP[]
  for (let i = 0; i < 10; i++) {
    data.push({
      codePostal: `404${i}`,
      nbrEpLed: '1240',
      nbrEpNonLed: '102',
      consomation: '10547 kWh',
      ean: `545454545${i}`,
      type: 'ELEC',
    })
  }

  return data
}

export const exportConsoEp = () => {
  const data = [] as ExportConsoEp[]
  for (let i = 0; i < 10; i++) {
    data.push({
      codePostal: `404${i}`,
      nbrEpLed: '1240',
      nbrEpNonLed: '102',
      consomation: '10547 kWh',
    })
  }

  return data
}

export const tableConsoEpEan = () => {
  const table: string[] = []
  const data = getConsoEp()
  for (const item of data) {
    if (!table.includes(item.ean)) {
      table.push(item.ean)
    }
  }

  return table
}

export const tableZipConsoEp = () => {
  const table: string[] = []
  const data = getConsoEp()
  for (const item of data) {
    if (!table.includes(item.codePostal)) {
      table.push(item.codePostal)
    }
  }

  return table
}

export const getCategoriesContact = () => {
  const contacts = [] as CommuneContactList[]
  let contactsCategory = [] as CommuneContact[]
  const { getCommuneId } = useGetters('user', ['getCommuneId'])
  const { VUE_APP_DOMAIN } = process.env
  axios
    .get(`${getApiUrl()}/town/contacts/${getCommuneId.value}`)
    .then(response => {
      const { data } = response
      let ContactEmail = ''
      let ContactPhone = ''
      if (data.townContacts) {
        data.townContacts.forEach(category => {
          contactsCategory = []
          category.townContactsPeople.forEach(contact => {
            if (contact.townContactsPeopleEmail?.label === undefined) {
              ContactEmail = contact.townContactsPeopleEmail
            } else {
              ContactEmail = contact.townContactsPeopleEmail?.label
            }
            if (contact.townContactsPeoplePhone?.label === undefined) {
              ContactPhone = contact.townContactsPeoplePhone
            } else {
              ContactPhone = contact.townContactsPeoplePhone?.label
            }
            contactsCategory.push({
              name: contact.townContactsPeopleName,
              mail: ContactEmail,
              phone: ContactPhone,
              picture: contact.townContactsPeopleAvatar
                ? `${VUE_APP_DOMAIN}${contact.townContactsPeopleAvatar.url}`
                : undefined,
            })
          })
          contacts.push({
            departement: category.townContactsCat,
            libelle: category.townContactsCatDescription,
            contacts: contactsCategory,
          })
        })
      }
    })
    .catch(error => {
      console.error(error)
    })

  return contacts
}
export const getDataConso = () => {
  const { getEp } = useGetters('commune', ['getEp'])
  const data: CommuneEpHistorique[] = getEp.value.historique

  return data
}
export const exportMonth = (month: string) => {
  const { i18n } = useState('commune', ['i18n'])
  const data = month.split('-')
  const months = {
    1: i18n.value.consoEp.mois.january,
    2: i18n.value.consoEp.mois.february,
    3: i18n.value.consoEp.mois.march,
    4: i18n.value.consoEp.mois.april,
    5: i18n.value.consoEp.mois.may,
    6: i18n.value.consoEp.mois.june,
    7: i18n.value.consoEp.mois.july,
    8: i18n.value.consoEp.mois.august,
    9: i18n.value.consoEp.mois.september,
    10: i18n.value.consoEp.mois.october,
    11: i18n.value.consoEp.mois.november,
    12: i18n.value.consoEp.mois.december,
  }

  return months[data[1]]
}

export const getMonth = () => {
  const months: string[] = []

  for (const item of getDataConso()) {
    if (!months.includes(exportMonth(item.date))) {
      months.push(exportMonth(item.date))
    }
  }

  return months
}

const orderDataByMonth = () => {
  const data: Record<string, string>[] = []

  for (const item of getDataConso()) {
    if (!data.includes(exportMonth(item.date.split('-')[1]))) {
      data.push({
        month: item.date.split('-')[1],
        consommation: '0',
      })
    }
  }

  return data
}
export const getGraphYears = (years: string[] = []) => {
  for (const item of getDataConso()) {
    if (!years.includes(item.date.split('-')[0])) {
      years.push(item.date.split('-')[0])
    }
  }

  return years
}

export const colorPerYears = triYears => {
  const colors = ['#FFE5AF', '#F7A600', '#E84E0F']
  const years = getGraphYears()
  const newCol: any[] = []

  for (let i = 0; i < years.length; i++) {
    newCol.push({ year: years[i], color: colors[i] })
  }

  return newCol.find(x => x.year === triYears)?.color
}

export const getDataByYears = (years: string[] = []) => {
  const { i18n } = useState('commune', ['i18n'])
  const results: CommuneDataConsoEp[] = []
  let dataPush: string[] = []
  // Par année, recuperé les conso et les mettre dans le tableau adapté
  for (let i = 0; i < years.length; i++) {
    // liste de l'ordre des mois recu
    const x = orderDataByMonth()
    for (const item of getDataConso()) {
      if (item.date.includes(years[i])) {
        for (const i of x) {
          // eslint-disable-next-line max-depth
          if (i.month === item.date.split('-')[1]) {
            i.consommation = item.consommation
          }
        }
      }
    }
    // formatting des datas avant envoie vers le composant
    let consoTotal = 0
    dataPush = []
    for (let i = 0; i < 12; i++) {
      dataPush.push(x[i].consommation)
      consoTotal += Number(x[i].consommation)
    }
    results.push({
      color: colorPerYears(years[i]),
      name: `${i18n.value.consoEp.annee} ${years[i]}`,
      data: dataPush,
      total: consoTotal,
    })
  }

  return results
}

/** fonction formattant ma liste des user pour les afficher dans le tableau
 *
 */
export const getRelevesIndexSmartData = () => {
  const dataList = [] as AdresseEan[]
  let eansList = [] as EanData[]
  const { getEansSmart } = useGetters('commune', ['getEansSmart'])

  for (const record of getEansSmart.value) {
    eansList = []
    for (const ean of record.eans) {
      let listReleves = ''
      let dateReleve: string | undefined = undefined
      if (ean.index && ean.index.length > 0) {
        dateReleve = dayjs(ean.index[0].date).format('DD/MM/YYYY')
        ean.index.forEach(item => {
          listReleves += `${convertRegister(item.cadran)} : ${
            item.index
          } ${convertUnit(item.unite)}<br/>`
        })
      }
      eansList.push({
        ean: ean.ean,
        numCpt: ean.numCpt,
        type: formatTypeCompteur(ean.type),
        date: dateReleve,
        releves: listReleves,
      })
    }
    dataList.push({
      adresse: record.adresse,
      eans: eansList,
    })
  }

  return dataList
}
export const getRelevesIndexData = () => {
  const dataList = [] as AdresseEan[]
  let eansList = [] as EanData[]
  const { getEans } = useGetters('commune', ['getEans'])

  for (const record of getEans.value) {
    eansList = []
    for (const ean of record.eans) {
      let listReleves = ''
      let dateReleve: string | undefined = undefined
      if (ean.index && ean.index.length > 0) {
        dateReleve = dayjs(ean.index[0].date).format('DD/MM/YYYY')
        ean.index.forEach(item => {
          listReleves += `${convertRegister(item.cadran)} : ${
            item.index
          } ${convertUnit(item.unite)}<br/>`
        })
      }
      eansList.push({
        ean: ean.ean,
        numCpt: ean.numCpt,
        type: formatTypeCompteur(ean.type),
        date: dateReleve,
        releves: listReleves,
      })
    }
    dataList.push({
      adresse: record.adresse,
      eans: eansList,
    })
  }

  return dataList
}

export const formatExportTitle = str => {
  const date = dayjs().format('YYYY_MM_DD')

  return `${str}_${date}`
}
