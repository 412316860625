import $logger from 'consola'
import auth from '@/composables/auth'
import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from '@/inc/app.config'

// Used for signup, password
export default async function fetch(
  path: string,
  method = 'get',
  data = {} as Record<string, unknown>,
  config = {} as Record<string, any>
) {
  let error
  let res: AxiosResponse | null = null
  const headers: Record<string, unknown> = {}

  try {
    if (auth.currentUser.value === null) {
      const ghost = await auth.currentGhostAsync

      if (
        ghost?.sessionId &&
        !config.headers?.SessionId &&
        path !== 'users/activate' // No SessionId for password activation
      ) {
        config.headers = {}
        config.headers.SessionId = ghost.sessionId
      }
    }

    if (path.includes('/sendmail')) {
      const customAxios = axios.create()
      delete customAxios.defaults.headers.common.Authorization
      res = await customAxios[method](`${getApiUrl()}/${path}`, data, config)
    } else {
      res = await axios[method](`${getApiUrl()}/${path}`, data, config)
    }
  } catch (err) {
    error = err
    $logger.error(err)
  }

  return {
    error,
    res,
  }
}
