





















































































































































import { useState } from '@u3u/vue-hooks'
import { defineComponent, ref, watch } from '@vue/composition-api'
import CustomLink from '@/components/g/CustomLink.vue'
import * as check from '@/composables/commune'

export default defineComponent({
  name: 'selector-commune',
  components: { CustomLink },
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    edit: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },

  setup(props, ctx) {
    const values = ref(props.edit)
    const result = ref(props.options)

    watch(values, oldVal => {
      ctx.root.$emit(`checkbox:selector:${props.type}`, values.value)
    })

    return {
      values,
      ...useState('commune', ['i18n']),
      check,
      result,
    }
  },
})
