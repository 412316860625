











































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  ref,
  onBeforeMount,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'

import VInput from '@/components/form/VInput.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import AutoSuggest from '@/components/commune/components/filter/AutoSuggest.vue'
import SelectCommune from './filter/SelectCommune.vue'
import Switcher from '@/components/ui/Switcher.vue'
import CustomLink from '@/components/g/CustomLink.vue'
const { VUE_APP_MYCOMMUNE_DOMAIN } = process.env

import { getCallbackUrl } from '@/inc/utils'
import userAccountRequest from '@/composables/userAccountRequest'
import { extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules.umd.js'
import * as check from '@/composables/commune'

extend('required', required)
extend('regex', regex)

export default defineComponent({
  name: 'my-commune-user-form',
  props: {
    model: {
      type: Object as () => Record<string, any>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    VInput,
    FeedbackMessage,
    SelectCommune,
    Switcher,
    CustomLink,
    AutoSuggest,
  },
  setup(props, ctx) {
    const { i18n } = useState('commune', ['i18n'])
    const { isAdmin } = useGetters('user', ['isAdmin'])
    const { FETCH_ROLES } = useActions('commune', ['FETCH_ROLES'])
    const { getTableRoles } = useGetters('commune', ['getTableRoles'])
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const { currentLang } = useGetters(['currentLang'])
    onBeforeMount(async () => {
      if (getTableRoles.value.length < 1) {
        await FETCH_ROLES()
      }
    })

    /*
    const gsmNew = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
          props.model.Tel,
          '+32############'
        )
      },
      set(val: string) {
        props.model.Tel = val.replace(/^0|\//, '')
      },
    })*/

    const sendedEmail = ref(false)

    const resetPassword = async email => {
      sendedEmail.value = true
      if (isAdmin.value) {
        const callback = getCallbackUrl('/reset/password/')
        const { error } = await userAccountRequest(
          `town/reset/sendmail?Callback=${
            VUE_APP_MYCOMMUNE_DOMAIN === 'http://mycommune.local:4000'
              ? 'https://macommune.myresa-qual.resa.be'
              : VUE_APP_MYCOMMUNE_DOMAIN
          }/${
            currentLang.value === 'de' ? 'de' : 'fr'
          }/reset/password/&Email=${email}`,
          'post'
        )
        // eslint-disable-next-line no-negated-condition
        if (!error) {
          hasError.value = false
          feedbackMessage.value = i18n.value.error.resetConfirm
          sendedEmail.value = false
        } else {
          hasError.value = true
          feedbackMessage.value = i18n.value.error.reset
          sendedEmail.value = false
        }
      }
    }

    const mailActivation = async (email, uid) => {
      sendedEmail.value = true
      const headers: Record<string, string> = {}
      headers.SessionId = uid
      if (isAdmin.value) {
        const { error } = await userAccountRequest(
          `town/activate/sendmail?Callback=${
            VUE_APP_MYCOMMUNE_DOMAIN === 'http://mycommune.local:4000'
              ? 'https://macommune.myresa-qual.resa.be'
              : VUE_APP_MYCOMMUNE_DOMAIN
          }/${currentLang.value === 'de' ? 'de' : 'fr'}/signup/password/`,
          'post',
          { Email: email},
          { headers }
        )
        // eslint-disable-next-line no-negated-condition
        if (!error) {
          hasError.value = false
          feedbackMessage.value = i18n.value.error.activationmailok
          sendedEmail.value = false
        } else {
          hasError.value = true
          feedbackMessage.value = i18n.value.error.activationmailko
          sendedEmail.value = false
        }
      }
    }


    return {
      ...useState('commune', ['i18n']),
      ...useGetters('user', ['isAdmin']),
      // gsmNew,
      i18n,
      sendedEmail,
      resetPassword,
      mailActivation,
      feedbackMessage,
      hasError,
      check,
      getTableRoles,
      // eslint-disable-next-line max-len
      mobile: /^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/,
      // eslint-disable-next-line
      email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    }
  },
})
