var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-block"},[_c('div',{staticClass:"h3"},[_vm._v(_vm._s(_vm.i18n.userGestion.info))]),_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.nom,"rules":{
        regex: /^[A-Za-zÀ-ÖØ-öø-ƿ\-]+[\sA-Za-zÀ-ÖØ-öø-ƿ\-.,']*$/,
        required: true,
      },"custom-messages":{
        regex: _vm.i18n.userGestion.nom + ' ' + _vm.i18n.error.invalid,
        required: _vm.i18n.error.required,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('v-input',{staticClass:"input--commune-alt",attrs:{"label":_vm.i18n.userGestion.nom,"required":true,"errors":errors,"disabled":!_vm.isAdmin,"id":_vm.i18n.userGestion.nom},on:{"input":validate},model:{value:(_vm.model.Nom),callback:function ($$v) {_vm.$set(_vm.model, "Nom", $$v)},expression:"model.Nom"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.prenom,"rules":{
        regex: /^[A-Za-zÀ-ÖØ-öø-ƿ\-]+[\sA-Za-zÀ-ÖØ-öø-ƿ\-.,']*$/,
        required: true,
      },"custom-messages":{
        regex: _vm.i18n.userGestion.prenom + ' ' + _vm.i18n.error.invalid,
        required: _vm.i18n.error.required,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('v-input',{staticClass:"input--commune-alt",attrs:{"label":_vm.i18n.userGestion.prenom,"required":true,"errors":errors,"disabled":!_vm.isAdmin,"id":_vm.i18n.userGestion.prenom},on:{"input":validate},model:{value:(_vm.model.Prenom),callback:function ($$v) {_vm.$set(_vm.model, "Prenom", $$v)},expression:"model.Prenom"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.email,"rules":{
        regex: _vm.email,
        required: true,
      },"custom-messages":{
        required: _vm.i18n.error.required,
        regex: _vm.i18n.userGestion.email + ' ' + _vm.i18n.error.invalid,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('v-input',{staticClass:"input--commune-alt",attrs:{"label":_vm.i18n.userGestion.email,"disabled":_vm.type === 'profile' || !_vm.isAdmin || _vm.type === 'editUser',"required":true,"errors":errors,"type":"email","id":_vm.i18n.userGestion.email},on:{"input":validate},model:{value:(_vm.model.Email),callback:function ($$v) {_vm.$set(_vm.model, "Email", $$v)},expression:"model.Email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.tel,"rules":{
        regex: _vm.mobile,
      },"custom-messages":{
        regex: _vm.i18n.userGestion.tel + ' ' + _vm.i18n.error.invalid,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('v-input',{staticClass:"input--commune-alt",attrs:{"label":_vm.i18n.userGestion.tel,"required":false,"disabled":!_vm.isAdmin,"errors":errors,"placeholder":"+32499123456"},on:{"input":validate},model:{value:(_vm.model.Tel),callback:function ($$v) {_vm.$set(_vm.model, "Tel", $$v)},expression:"model.Tel"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.departement,"rules":{
        regex: /^[A-Za-zÀ-ÖØ-öø-ƿ\-]+[\sA-Za-zÀ-ÖØ-öø-ƿ\-.,']*$/,
        required: true,
      },"custom-messages":{
        regex: _vm.i18n.userGestion.departement + ' ' + _vm.i18n.error.invalid,
        required: _vm.i18n.error.required,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('AutoSuggest',{attrs:{"type":_vm.type,"model":_vm.model,"errors":errors,"items":_vm.check.tableDepartement()},on:{"input":validate}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.fonction,"rules":{
        regex: /^[A-Za-zÀ-ÖØ-öø-ƿ\-]+[\sA-Za-zÀ-ÖØ-öø-ƿ\-.,']*$/,
      },"custom-messages":{
        regex: _vm.i18n.userGestion.fonction + ' ' + _vm.i18n.error.invalid,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('v-input',{staticClass:"input--commune-alt",attrs:{"label":_vm.i18n.userGestion.fonction,"required":false,"errors":errors,"disabled":_vm.type === 'profile' || !_vm.isAdmin,"id":_vm.i18n.userGestion.fonction},on:{"input":validate},model:{value:(_vm.model.Fonction),callback:function ($$v) {_vm.$set(_vm.model, "Fonction", $$v)},expression:"model.Fonction"}})]}}])})],1),(_vm.type === 'editUser')?_c('div',{staticClass:"form-block"},[_c('div',{staticClass:"h3"},[_vm._v(_vm._s(_vm.i18n.userGestion.statut))]),(_vm.model.Valide)?_c('switcher',{staticClass:"switcher--dual",attrs:{"disabled":!_vm.isAdmin,"items":[
        {
          label: _vm.i18n.userGestion.actif,
          value: true,
          isActive: _vm.model.Actif,
        },
        {
          label: _vm.i18n.userGestion.inactif,
          value: false,
          isActive: !_vm.model.Actif,
        } ]},model:{value:(_vm.model.Actif),callback:function ($$v) {_vm.$set(_vm.model, "Actif", $$v)},expression:"model.Actif"}}):_c('span',{staticClass:"nonvalide"},[_vm._v(_vm._s(_vm.i18n.userGestion.nonvalide))])],1):_vm._e(),(_vm.type === 'editUser' && _vm.model.Valide)?_c('div',{staticClass:"form-block"},[_c('div',{staticClass:"h3"},[_vm._v(_vm._s(_vm.i18n.userGestion.password))]),_c('CustomLink',{staticClass:"submitForm",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.userGestion.resetPwd,
      },"modifiers":['btn', 'green', 'big', 'rectrounded'],"type":"button","disabled":_vm.sendedEmail},nativeOn:{"click":function($event){return _vm.resetPassword(_vm.model.Email)}}}),(_vm.feedbackMessage)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{
        type: _vm.hasError ? 'error' : 'success',
        htmltext: _vm.feedbackMessage,
      }}}):_vm._e()],1):_vm._e(),(_vm.type === 'editUser' && !_vm.model.Valide)?_c('div',{staticClass:"form-block"},[_c('div',{staticClass:"h3"},[_vm._v(_vm._s(_vm.i18n.userGestion.mailactivation))]),_c('CustomLink',{staticClass:"submitForm",attrs:{"content":{
        tag: 'button',
        label: 'Envoyer',
      },"modifiers":['btn', 'green', 'big', 'rectrounded'],"type":"button","disabled":_vm.sendedEmail},nativeOn:{"click":function($event){return _vm.mailActivation(_vm.model.Email, _vm.model.Uid)}}}),(_vm.feedbackMessage)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{
        type: _vm.hasError ? 'error' : 'success',
        htmltext: _vm.feedbackMessage,
      }}}):_vm._e()],1):_vm._e(),(_vm.type !== 'profile')?_c('div',{staticClass:"form-block"},[_c('ValidationProvider',{attrs:{"name":_vm.i18n.userGestion.roles,"rules":{
        required: true,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('div',{staticClass:"h3"},[_vm._v(_vm._s(_vm.i18n.userGestion.roles)+" *")]),_c('SelectCommune',{class:'--inline-block',attrs:{"required":true,"errors":errors,"options":_vm.getTableRoles,"label":_vm.i18n.userGestion.roles,"type":_vm.type,"edit":_vm.type === 'editUser' ? _vm.model.Roles : []},on:{"input":validate},model:{value:(_vm.model.Roles),callback:function ($$v) {_vm.$set(_vm.model, "Roles", $$v)},expression:"model.Roles"}})]}}],null,false,3325342230)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }